<template>
    <div v-show="isShow">
        <div class="popup-box">
            <div class="line"></div>
            <div class="delete" @click="deleteConfirm">
                <img src="../../assets/images/delete.png" alt="" width="24" height="24">
                <span>删除</span>
            </div>
            <div @click="show" class="cancel-button">取消</div>
        </div>
        <div class="shadow" @click="show"></div>
    </div>
</template>

<script>
export default {
  name: 'PopUp',
  components: {
  },
  data(){
        return {
            isShow: false,
            tid:''
        }
  },
  methods:{
        show(){
            this.isShow = !this.isShow
        },
        deleteConfirm(){
            this.$emit('deleteConfirm')
        },
  },
  props:{

  }
}
</script>

<style scoped lang="scss">
.shadow {
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.3);
}
.popup-box {
    width: 100%;
    height: 180px;
    position: fixed;
    background: #FFF;
    bottom: -10px;
    left: 0;
    right: 0;
    z-index: 999;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    .line {
        height: 5px;
        width: 35px;
        background: #cdcdcd;
        border-radius: 5px;
        margin: 15px auto 0;
    }
    .delete {
        width: 100%;
        height: 50px;
        padding: 0 20px;
        margin: 15px 0;
        line-height: 50px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        color: #000;
        text-align: center;
        font-size: 20px;
        span {
            color: #d81e06;
            padding-left: 10px;
            font-size: 17px;
        }
    }
    .cancel-button {
        color: #2c2c2c;
        width: calc(100% - 40px);
        height: 45px;
        line-height: 45px;
        text-align: center;
        margin: 0 20px;
        border-radius: 8px;
        background: rgba(0,0,0,0.1);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
