<template>
    <div>
        <div v-if="isWrite" class="comment-write-box">
            <textarea ref="text" placeholder="友善评论, 营造良好社区氛围" class="textarea" v-model="text" maxlength="100" rows="1"></textarea>
            <div class="disabled" v-if="!text">发送</div>
            <div class="send" @click="submit" v-else>发送</div>
        </div>
        <div v-else class="comment-footer-box">
            <div class="input" @click="write">
                <img src="../../assets/images/write.png" alt="" width="20" height="20">
                说点什么...
            </div>
            <div class="icon">
                <div v-if="isLike" @click="handleLike" class="like">
                    <img src="../../assets/images/like.png" alt="" width="20" height="20" >
                    <div>{{ likeAmount }}</div>
                </div>
                <div v-else @click="handleLike" class="unlike">
                    <img src="../../assets/images/unlike.png" alt="" width="20" height="20" >
                    <div v-if="likeAmount === 0">点赞</div>
                    <div v-else>{{ likeAmount }}</div>
                </div>
            </div>
            <div @click="write" class="comment-icon">
                <img src="../../assets/images/comment.png" alt="" width="20" height="20">
                <div>评论</div>
            </div>
        </div>
        <div class="shadow" @click="showWrite" v-show="isWrite"></div>
        <login-dialog ref="login"/>
    </div>
</template>

<script>
import { comment } from '@/api/api'
import LoginDialog from '../dialog/LoginDialog.vue'
export default {
  name: 'Comment',
  components: {
    LoginDialog
  },
  data(){
      return {
        isWrite: false,
        isReply: false, // 判断是否时回复评论
        text:'',
        isLogin: localStorage.getItem('token') ? true : false,
      }
  },
  props:{
    cid:{
        type: Number || String,
        default: 0,
    },
    isLike: {
        type: Boolean,
        default: false
    },
    likeAmount:{
        type: Number || String,
        default: 0,
    }
  },
  methods:{
    handleLike(){
        this.$emit('handleLike')
    },
    // 回复评论
    writeReply(){
        this.isWrite = true
        this.isReply = true
        this.$nextTick(()=>{
            this.$refs.text.focus()
        })
    },
    // 评论
    write(){
        if(this.isLogin){
            this.isWrite = true
            this.isReply = false
            this.$nextTick(()=>{
                this.$refs.text.focus()
            })
        } else {
            // 弹窗跳转到登录页
            this.$refs.login.show()
        }
    },
    showWrite(){
        this.isWrite = false
        this.isReply = false
    },
    submit(){
        let uid = this.isLogin ? String(JSON.parse(localStorage.getItem('userInfo'))[0].uid) : ''
        if(this.isReply){
            // 回复某条评论
            comment({ tid: this.$route.query.tid, cid: this.cid, uid: uid, comment: this.text }).then(()=>{
                this.isWrite = false
                this.isReply = false
                this.text = ''
                this.$emit('getCommentData')
            })
        } else {
            // 发一条评论
            comment({ tid: this.$route.query.tid, uid: uid, comment: this.text }).then(()=>{
                this.isWrite = false
                this.isReply = false
                this.text = ''
                this.$emit('getCommentData', 'scrollToBottom')
            })
        }
    }
  },
}
</script>

<style scoped lang="scss">
.shadow {
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.3);
}
.comment-footer-box {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 55px;
    max-width: 625px;
    margin: 0 auto;
    border-top: 1px solid rgba(0, 0, 0, 0.05);;
    display: flex;
    align-items: center;
    background: #fff;
    .input {
        // width: 130px;
        width: 70%;
        background: #f3f3f3;
        color: #7a7a7a;
        height: calc(100% - 10px);
        box-sizing: border-box;
        margin: 5px 10px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            margin-right: 5px;
        }
    }
    .icon {
        .like {
            margin: 0 15px;
            min-width: 35px;
            div {
                color: #d81e06;
                font-size: 12px;
            }
        }
        .unlike {
            margin: 0 15px;
            min-width: 35px;
            div {
                color: #969699;
                font-size: 12px;
            }
        }
    }
    .comment-icon {
        font-size: 12px;
        margin: 0 15px;
        min-width: 35px;
        color: #969699;
    }
}
.comment-write-box {
    position: fixed;
    z-index: 999;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.05);;
    background: #fafafa;
    display: flex;
    align-items: center;
    max-width: 625px;
    margin: 0 auto;
    left: 0;
    right: 0;
    .textarea{
        width: 100%;
        margin: 10px 0px 10px 10px;
        padding: 15px;
        box-sizing: border-box;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: #1E1043;
        line-height: 20px;
        outline: 0;
        word-wrap: break-word;
        overflow-x: hidden;
        overflow-y: auto;
        background: #f3f3f3;
        border-radius: 10px;
    }
    textarea{
        border: none;
        resize:none;
        outline:none;
        min-height: 90px;
        padding: 0;
    }
    .disabled {
        width: 60px;
        height: 20px;
        line-height: 20px;
        margin: 0 10px;
        padding: 5px 10px;
        border-radius: 15px;
        background: #ddd;
        color: #fff;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .send {
        width: 60px;
        height: 20px;
        line-height: 20px;
        margin: 0 10px;
        padding: 5px 10px;
        border-radius: 15px;
        background: #ea6f7b;
        color: #fff;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
