<template>
  <div>
    <Nav :isLoginAuthor="isLoginAuthor"></Nav>
    <div class="article-box" id="article-box">
        <div class="title" v-if="hasTitle">{{ article.title }}</div>
        <div class="author">
          <div class="info">
            <img :src="userInfo.avatarUrl" alt="" width="36" height="36" @click="goAuthorCenter"  v-show="!$store.state.loading">
            <span v-show="!$store.state.loading">{{ userInfo.nickname }}</span>
          </div>
          <div v-show="!isLoginAuthor && !$store.state.loading">
            <div class="followed-button" v-if="isFollowed" @click="handleFollow">已关注</div>
            <div class="follow-button primary" v-else @click="handleFollow"><span>+</span>关注</div>
          </div>
        </div>
        <div v-show="!$store.state.loading">
          <div v-html="article.context_html" class="context" v-lazy-container="{
                 selector: 'img', error: require('../assets/images/default-shop-desc-swiper.png'),
                 loading:require('../assets/images/default-shop-desc-swiper.png') }" v-if="$store.state.userAgent === 'Mobile'">
          </div>
          <div v-html="article.context_html" class="context" v-lazy-container="{
                 selector: 'img', error: require('../assets/images/625_625_bg.png'),
                 loading:require('../assets/images/625_625_bg.png') }" v-else>
          </div>
        </div>
        <div class="like">
          <div v-show="!$store.state.loading">
            <div class="like-img" v-if="isLike" @click="handleLike">
              <div>
                <img src="../assets/images/like.png" alt="" width="20" height="20" >
                <div>{{article.like_amount}}</div>
              </div>
            </div>
            <div class="unlike-img" v-else @click="handleLike">
              <div>
                <img src="../assets/images/unlike.png" alt="" width="20" height="20" >
                <div v-if="article.like_amount === 0" class="text">首赞</div>
                <div v-else>{{article.like_amount}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-tips" v-show="!$store.state.loading">编辑于 {{ article.createTime }} <span>·</span> 著作权归作者所有</div>
        <div class="comment-block" :key="commentKey" v-show="!$store.state.loading">
          <div class="comment-and-reply" v-for="(comment,i) in commentList" :key="i">
            <div class="comment">
              <div class="left">
                <img :src="comment.avatarUrl" alt="" width="30" height="30">
              </div>
              <div class="center">
                <div class="nickname">{{ comment.nickname }}</div>
                <div class="text" @click="openAriclePopup(comment.cid, 'comment', comment.uid)">{{ comment.comment }}</div>
              </div>
              <div class="right" v-if="comment.isLike">
                <img src="../assets/images/user-like.png" alt="" width="26" height="26" @click="handleCommentLike(comment.uid, comment.cid, comment.isLike)">
                <div>{{ comment.amount }}</div>
              </div>
              <div class="right" v-else>
                <img src="../assets/images/user-unlike.png" alt="" width="26" height="26" @click="handleCommentLike(comment.uid, comment.cid, comment.isLike)">
                <div>{{ comment.amount > 0 ? comment.amount : '点赞' }}</div>
              </div>
            </div>
            <div class="reply" v-for="(reply,j) in comment.replyList" :key="j">
              <div class="reply-left">
                <img :src="reply.avatarUrl" alt="" width="20" height="20">
              </div>
              <div class="reply-center">
                <div class="reply-nickname">{{ reply.nickname }}</div>
                <div class="reply-comment" @click="openAriclePopup(comment.cid, 'reply', reply.uid, reply.rid)">{{ reply.comment }}</div>
              </div>
              <div class="reply-right" v-if="reply.isLike">
                <img src="../assets/images/user-like.png" alt="" width="26" height="26" @click="handleReplyLike(reply.uid, comment.cid, reply.rid, reply.isLike)">
                <div>{{ reply.amount }}</div>
              </div>
              <div class="reply-right" v-else>
                <img src="../assets/images/user-unlike.png" alt="" width="26" height="26" @click="handleReplyLike(reply.uid, comment.cid, reply.rid ,reply.isLike)">
                <div>{{ reply.amount > 0 ? reply.amount : '点赞' }}</div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <login-dialog ref="login"/>
    <PopUp ref="popup" @deleteConfirm="deleteConfirm"/>
    <ArticlePopUp ref="ariclePopup" @openWritingDialog="openWritingDialog" @delConfirm="delConfirm" :isAuthor="isAuthor"/>
    <Confirm ref="confirm" @handleDelete="handleDelete" />
    <login-reminder ref="loginReminder"/>
    <Comment @handleLike="handleLike" @getCommentData="getCommentData" :likeAmount="article.like_amount" ref="comment" :cid="cid" :isLike="isLike"/>
  </div>
</template>

<script>
import { queryArticleById, deleteArticleByTid, followAuthor, unfollowAuthor, like, unlike, deleteUpload,
 queryCommentList, commentLike, commentUnLike, deleteComment, deleteReply, wxSdk } from '@/api/api'
import PopUp from "../components/dialog/PopUp.vue";
import Nav from "../components/nav/index.vue"
import Confirm from '../components/dialog/Confirm.vue';
import LoginDialog from '../components/dialog/LoginDialog.vue';
import Comment from '../components/footer/Comment.vue';
import ArticlePopUp from '../components/dialog/ArticlePopUp.vue';
import wx from "weixin-js-sdk";
import LoginReminder from '../components/dialog/LoginReminder.vue';
const seo = require('../../config/index')

export default {
  name: 'Article',
  created(){
    this.getData()
    this.$bus.$on('more',this.more)
  },
  mounted(){
    this.isLogin = localStorage.getItem('token') ? true : false
    if(!this.isLogin){
      this.$refs.loginReminder.show()
    } else {
      this.$refs.loginReminder.close()
    }
    // this.$refs.article.scrollTop = this.$refs.article.scrollHeight // 代码无效后续要处理
  },
  components: {
    PopUp,
    Nav,
    Confirm,
    LoginDialog,
    Comment,
    ArticlePopUp,
    LoginReminder
  },
  data:()=>{
    return {
      article:{
        title:'',
        context_html: '',
        createTime: '',
        like_amount: 0,
      },
      userInfo:{
        avatarUrl: require('../assets/images/avatar.png'),
        nickname: '',
        uid: 1
      },
      cid: 0, // 删除时要用到
      rid: 0, // 删除时要用到
      authUid: 0, // 删除时要用到
      isLoginAuthor: false,
      isAuthor: false, // 是否是发表评论的作者，是否是回复评论的作者
      isLogin: localStorage.getItem('token') ? true : false,
      isFollowed: false,
      isFocusEachOther: false,
      isLike: false,
      isLikeComment: false,
      commentList:[],
      commentKey: 0,
      hasTitle: false,
    }
  },
  methods:{
    getData(){
      this.$store.commit('Set_Loading_State', true)
      let uid = this.isLogin ? String(JSON.parse(localStorage.getItem('userInfo'))[0].uid) : ''
      queryArticleById({ tid: Number(this.$route.query.tid), uid: uid }).then(res=>{
        if(res.data.data.length > 0){
          this.article.title = res.data.data[0].title
          this.hasTitle = Boolean(res.data.data[0].title)
          if(this.$store.state.userAgent === 'PC'){
            if(this.hasTitle){
              document.getElementById("article-box").style.cssText= 'padding-top: 5px;';
            } else {
              document.getElementById("article-box").style.cssText= 'padding-top: 20px;';
            }
          }
          // seo
          if(res.data.data[0].title){
            document.title = res.data.data[0].title + '-' + seo.config.seoPage.articlePage.title.split('#')[1]
          } else {
            document.title = seo.config.seoPage.default.title
          }
          let keywords = seo.config.seoPage.articlePage.keywords
          let description = res.data.data[0].context
          document.querySelector('meta[name="keywords"]').setAttribute('content', keywords)
          document.querySelector('meta[name="description"]').setAttribute('content', description)
          this.article.context_html = res.data.data[0].context_html.replace(/src/g, "data-src")
          this.article.context = res.data.data[0].context
          this.article.createTime = res.data.data[0].createdAt
          this.article.imgUrl = res.data.data[0].imgUrl // 用户如果要删除文章，同时删除已存的图片
          this.article.like_amount = res.data.data[0].like_amount
          this.userInfo.uid = res.data.data[0].uid
          this.userInfo.nickname = res.data.data[0].nickname
          this.isFollowed = res.data.data[0].isFollowed
          this.isFocusEachOther = res.data.data[0].focusEachOther
          this.isLike = res.data.data[0].isLike
          let miniAvatarUrl = res.data.data[0].miniAvatarUrl
          if(miniAvatarUrl){
            this.userInfo.avatarUrl = miniAvatarUrl
          } else {
            this.userInfo.avatarUrl = require('../assets/images/avatar.png')
          }
          // 判断是否是已登录的作者，提供文章删除入口
          if(localStorage.getItem('userInfo') && res.data.data[0].uid === JSON.parse(localStorage.getItem('userInfo'))[0].uid){
            this.isLoginAuthor = true
          }
          let wxShareImgUrl
          if(res.data.data[0].imgUrl){
            wxShareImgUrl = seo.config.wxConfig.articlePage.link + res.data.data[0].imgUrl.split(',')[0]
          } else {
            wxShareImgUrl = this.userInfo.avatarUrl
          }
          let url = seo.config.wxConfig.articlePage.link + this.$route.fullPath + '&share=wx'
          let title = this.article.title + + '-' + seo.config.wxConfig.articlePage.title.split('#')[1]
          let context = this.article.context
          wxSdk({ url:url }).then(res=>{
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: seo.config.wxConfig.appId, // 必填，公众号的唯一标识
              timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.data.signature,// 必填，签名
              jsApiList: ['checkJsApi', 'updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage','onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareQZone'] // 必填，需要使用的JS接口列表
            });
            wx.ready(function () {
              wx.checkJsApi({
                jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage','onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareQZone'], // 必填，需要使用的JS接口列表
                success: function(res) {
                  // “分享给朋友”及“分享到QQ”
                  if (res.checkResult.updateAppMessageShareData || res.checkResult.onMenuShareAppMessage) {
                    wx.updateAppMessageShareData({ 
                      title: title, // 分享标题
                      desc: context, // 分享描述
                      link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                      imgUrl: wxShareImgUrl, // 分享图标
                      success: function () {
                        // 设置成功
                      }
                    })
                  }
                  // “分享到朋友圈”及“分享到 QQ 空间”
                  if(res.checkResult.updateTimelineShareData || res.checkResult.onMenuShareTimeline){
                    wx.updateTimelineShareData({ 
                      title: title, // 分享标题
                      link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                      imgUrl: wxShareImgUrl, // 分享图标
                      success: function () {
                        // 设置成功
                      }
                    })
                  }
              }})
            })
          })
        } else {
        // 如果某tid的文章已删除，请求结果len为0，重定向回首页
          this.$router.push('/')
        }
        setTimeout(()=>{
          this.$store.commit('Set_Loading_State', false)
        },300)
      })
      this.getCommentData()
    },
    getCommentData(scroll){
      let uid = this.isLogin ? String(JSON.parse(localStorage.getItem('userInfo'))[0].uid) : ''
      queryCommentList({ tid: this.$route.query.tid, uid: uid }).then((res)=>{
        this.commentList = res.data.data
        this.commentKey += 1 // 页面用户缓存，加key变量，回去一次数据，更新一次视图
        if(scroll === 'scrollToBottom'){
            this.goScrollBottom()
        }
      })
    },
    goScrollBottom(){
      this.$nextTick(()=>{
        let height = document.documentElement.scrollHeight || document.body.scrollHeight;
        document.body.scrollTop && document.body.scrollTo({top: height, behavior: 'smooth'})
        document.documentElement.scrollTop && document.documentElement.scrollTo({top: height, behavior: 'smooth'})
      })
    },
    handleCommentLike(authUid, cid, isLike){
      let uid = this.isLogin ? String(JSON.parse(localStorage.getItem('userInfo'))[0].uid) : ''
      if(this.isLogin){
        // 更新本地commentList
        let tempArr = []
        this.commentList.forEach(item => {
          let temp = {}
          temp.avatarUrl = item.avatarUrl
          temp.cid = item.cid
          temp.comment = item.comment
          temp.nickname = item.nickname
          temp.replyList = item.replyList
          temp.uid = item.uid
          temp.amount = item.amount
          // 更新点赞数量
          if(isLike && item.cid === cid){
            temp.amount = item.amount - 1
          } else if(!isLike && item.cid === cid) {
            temp.amount = item.amount + 1
          } else {
            temp.amount = item.amount
          }
          // 更新点赞状态
          if(item.cid === cid){
            temp.isLike = !item.isLike
          } else {
            temp.isLike = item.isLike
          }
          tempArr.push(temp)
        })
        this.commentList = tempArr
        // 更新远程评论列表
        if(isLike){
          commentUnLike({ uid: uid, authUid: authUid, cid: cid, tid: this.$route.query.tid }).then(()=>{
            console.log('取消评论点赞成功')
          })
        } else {
          commentLike({ uid: uid, authUid: authUid, cid: cid, tid: this.$route.query.tid }).then(()=>{
            console.log('评论点赞成功')
          })
        }
      } else {
        // 弹窗跳转到登录页
        this.$refs.login.show()
      }
    },
    handleReplyLike(authUid, cid, rid, isLike){
      let uid = this.isLogin ? String(JSON.parse(localStorage.getItem('userInfo'))[0].uid) : ''
      if(this.isLogin){
        // 更新本地commentList中的replyList
        let tempArr = []
        this.commentList.forEach(item => {
          let tempReplyList = []
          let temp = {}
          temp.avatarUrl = item.avatarUrl
          temp.cid = item.cid
          temp.comment = item.comment
          temp.amount = item.amount
          temp.nickname = item.nickname
          if(temp.cid === cid){
            item.replyList.forEach(list=>{
              let tempReply = {}
              tempReply.avatarUrl = list.avatarUrl
              tempReply.comment = list.comment
              tempReply.nickname = list.nickname
              tempReply.rid = list.rid
              tempReply.uid = list.uid
              // 更新点赞数量
              if(isLike && list.rid === rid){
                tempReply.amount = list.amount - 1
              } else if(!isLike && list.rid === rid) {
                tempReply.amount = list.amount + 1
              } else {
                tempReply.amount = list.amount
              }
              // 更新状态
              if(list.rid === rid){
                tempReply.isLike = !list.isLike
              } else {
                tempReply.isLike = list.isLike
              }
              tempReplyList.push(tempReply)
            })
            temp.replyList = tempReplyList
          } else {
            temp.replyList = item.replyList
          }
          temp.uid = item.uid
          temp.isLike = item.isLike
          tempArr.push(temp)
        })
        this.commentList = tempArr
        // 更新远程评论列表
        if(isLike){
          commentUnLike({ uid: uid, authUid: authUid, cid: cid, rid: rid, tid: this.$route.query.tid }).then(()=>{
            console.log('取消回复点赞成功')
          })
        } else {
          commentLike({ uid: uid, authUid: authUid, cid: cid, rid: rid, tid: this.$route.query.tid }).then(()=>{
            console.log('回复点赞成功')
          })
        }
      } else {
        // 弹窗跳转到登录页
        this.$refs.login.show()
      }
    },
    // 删除文章确认
    deleteConfirm(){
      this.$refs.confirm.show('article')
      this.$refs.popup.show()
    },
    // 删除评论或回复确认
    delConfirm(type){
      this.$refs.confirm.show(type)
      this.$refs.ariclePopup.show()
    },
    handleDelete(type){
        let uid = this.isLogin ? String(JSON.parse(localStorage.getItem('userInfo'))[0].uid) : ''
        if(type === 'article'){
          this.$refs.confirm.showLoading()
          deleteArticleByTid({ tid: this.$route.query.tid, uid: this.userInfo.uid }).then(()=>{
              let imgUrlArr = this.article.imgUrl.split(',')
              if(this.article.imgUrl){
                for(let i=0; i < imgUrlArr.length; i++){
                  deleteUpload({ imgUrl: imgUrlArr[i] })
                }
              }
              this.$refs.confirm.show()
              this.$notify({
                  message: '删除成功',
                  duration: 1000,
                  background: '#ec745b'
              })
              this.$refs.confirm.showLoading()
              setTimeout(()=>{
                this.$router.back()
              },800)
          })
        } else if(type === 'comment'){
          // 根据tid、cid删除评论
          deleteComment({ tid: this.$route.query.tid, cid: this.cid, authUid: this.authUid, uid: uid }).then(()=>{
            this.$refs.confirm.show()
            this.getCommentData()
          })
        } else if(type === 'reply'){
          // 根据tid、cid、rid删除回复
          deleteReply({ tid: this.$route.query.tid, cid: this.cid, rid: this.rid, authUid: this.authUid, uid: uid }).then(()=>{
            this.$refs.confirm.show()
            this.getCommentData()
          })
        }
    },
    more(){
      if(this.$refs.popup){
        this.$refs.popup.show()
      }
    },
    goAuthorCenter(){
      this.$router.push({path:'/author', query:{uid: this.userInfo.uid}})
    },
    openAriclePopup(cid, type, uid, rid){
      if(this.isLogin){
        this.$refs.ariclePopup.show(type)
        this.cid = cid // 删除时要用到
        if(rid || rid === 0){
          this.rid = rid // 删除时要用到
        }
        this.authUid = uid // 删除时要用到
        // 判断是否是已登录的作者，提供评论、回复删除入口
        if(localStorage.getItem('userInfo') && Number(uid) === Number(JSON.parse(localStorage.getItem('userInfo'))[0].uid)){
          this.isAuthor = true
        } else {
          this.isAuthor = false
        }
      } else {
          // 弹窗跳转到登录页
          this.$refs.login.show()
      }
    },
    openWritingDialog(){
      this.$refs.comment.writeReply()
      this.$refs.ariclePopup.show()
    },
    handleFollow(){
      if(this.isFollowed){
        // 请求unFollow接口
        if(this.isLogin){
          unfollowAuthor({ uid: JSON.parse(localStorage.getItem('userInfo'))[0].uid, unfollowUid:this.userInfo.uid }).then(()=>{
            console.log('取消关注成功')
            this.isFollowed = !this.isFollowed
            this.isFocusEachOther = false
          })
        } else {
          // 弹窗跳转到登录页
          this.$refs.login.show()
        }
      } else {
        // 请求follow接口
        if(this.isLogin){
          followAuthor({ uid: JSON.parse(localStorage.getItem('userInfo'))[0].uid, followUid:this.userInfo.uid }).then(()=>{
            console.log('关注成功')
            this.isFollowed = !this.isFollowed
            // this.isFocusEachOther = false
          })
        } else {
          // 弹窗跳转到登录页
          this.$refs.login.show()
        }
      }
    },
    handleLike(){
      if(this.isLike){
        // 请求unlike接口
        if(this.isLogin){
          let uid = this.isLogin ? String(JSON.parse(localStorage.getItem('userInfo'))[0].uid) : ''
          unlike({ tid:this.$route.query.tid, uid: uid }).then(()=>{
            console.log('取消点赞成功')
            this.isLike = !this.isLike
            this.article.like_amount -= 1
          })
        } else {
          // 弹窗跳转到登录页
          this.$refs.login.show()
        }
      } else {
        // 请求like接口
        if(this.isLogin){
          let uid = this.isLogin ? String(JSON.parse(localStorage.getItem('userInfo'))[0].uid) : ''
          like({ tid:this.$route.query.tid, uid: uid }).then(()=>{
            console.log('点赞成功')
            this.isLike = !this.isLike
            this.article.like_amount += 1
          })
        } else {
          // 弹窗跳转到登录页
          this.$refs.login.show()
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.article-box{
  max-width: 625px;
  margin: 50px auto 10px;
  background: #fff;
  text-align: left;
  min-height: calc(100vh - 60px);
  box-sizing: border-box;
  .title {
    color: #121212;
    font-size: 26px;
    font-weight: bold;
    margin: 20px 15px 0px;
    padding-top: 10px;
    min-height: 45px;
    box-sizing: border-box;
    font-family:  -apple-system,BlinkMacSystemFont,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Segoe UI","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
  }
  .author {
    display: flex;
    min-height: 36px;
    align-items: center;
    justify-content: space-between;
    padding: 15px 15px 20px;
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        margin-right: 10px;
        border-radius: 50%;
      }
      span {
        color: #7a7a7a;
      }
    }
    .follow-button {
      width: 68px;
      height: 30px;
      display: flex;
      line-height: 30px;
      border-radius: 15px;
      align-items: center;
      font-size: 15px;
      color: #fff;
      justify-content: center;
      border: 1px solid #ea6f7b;
      box-shadow: 1px 1px 4px #ea6f7b;
      span {
        font-size: 20px;
        color: #fff;
        padding-right: 2px;
      }
    }
    .followed-button {
      width: 68px;
      height: 30px;
      display: flex;
      line-height: 30px;
      border-radius: 15px;
      font-size: 15px;
      align-items: center;
      color: #fff;
      justify-content: center;
      border: 1px solid #ccc;
      background: #ccc;
      box-shadow: 1px 1px 4px #888;
    }
  }
  .context {
    margin: 0 15px;
    color: #121212;
    font-size: 20px;
  }
  .comment-block {
    border-top: 1px solid #f3f3f3;
    margin-bottom: 56px;
    margin-top: 30px;
    .comment-and-reply {
      padding: 15px 0px 0px;
      border-bottom: 1px solid #f3f3f3;
    }
  }
  .comment-and-reply > div:last-child {
    margin-bottom: 20px;
  }
  .bottom-tips {
    margin: 15px 15px 20px;
    font-size: 12px;
    color: #969699;
    span {
      font-weight: bold;
    }
  }
  .comment {
    display: flex;
    padding: 0px 15px;
    .left {
      img {
        border-radius: 50%;
      }
    }
    .center {
      width: 100%;
      font-size: 15px;
      color: rgba(0,0,0,0.6);
      margin: 0 10px 0 10px;
      .nickname {
        margin-bottom: 2px;
      }
      .text {
        color: rgba(0,0,0,0.80);
      }
    }
    .right {
      width: 26px;
      min-width: 48px;
      height: 40px;
      text-align: center;
      margin:0 0 0 15px;
      div {
        font-size: 12px;
        margin-top: -3px;
      }
    }
  }
  .reply {
    display: flex;
    margin: 15px 15px 20px 50px;
    .reply-left {
      img {
        border-radius: 50%;
      }
    }
    .reply-center {
      margin: 0 5px;
      width: 100%;
      .reply-nickname {
        margin-bottom: 2px;
      }
    }
    .reply-right {
      width: 26px;
      min-width: 48px;
      height: 40px;
      text-align: center;
      margin: 0 0 0 15px;
      div {
        font-size: 12px;
        margin-top: -3px;
      }
    }
  }
  .like {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    .unlike-img { 
      border: 1px solid rgba(0,0,0,0.5);
      border-radius: 50%;
      height: 60px;
      width: 60px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-bottom: -3px
      }
      div {
        color: rgba(0,0,0,0.5);
        padding: 0;
        text-align: center;
      }
      .text {
        font-size: 14px;
      }
    }
    .like-img {
      border: 1px solid #d81e06;
      border-radius: 50%;
      height: 60px;
      width: 60px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-bottom: -3px
      }
      div {
        color: #d81e06;
        padding: 0;
        text-align: center;
      }
    }
  }
}
</style>
