<template>
    <div v-show="isShow">
        <div class="popup-box">
            <div class="line"></div>
            <div class="reply" @click="openWritingDialog">
                <span>{{ firstItem }}</span>
            </div>
            <div class="delete" @click="delConfirm" v-if="isAuthor">
                <span>{{ secItem }}</span>
            </div>
            <div @click="show" class="cancel-button">取消</div>
        </div>
        <div class="shadow" @click="show"></div>
    </div>
</template>

<script>
export default {
  name: 'ArticlePopUp',
  components: {
  },
  data(){
        return {
            isShow: false,
            tid:'',
            firstItem:'回复评论',
            secItem:'删除评论',
        }
  },
  methods:{
        show(type){
            this.isShow = !this.isShow
            if(type === 'comment'){
                this.firstItem = '回复评论'
                this.secItem = '删除评论'
            } else if(type === 'reply'){
                this.firstItem = '回复评论'
                this.secItem = '删除回复'
            }
            if(type){
                this.type = type
            }
        },
        openWritingDialog(){
            this.$emit('openWritingDialog')
        },
        delConfirm(){
            this.$emit('delConfirm', this.type)
        },
  },
  props:{
    isAuthor:{
        default: false,
        type: Boolean
    }
  }
}
</script>

<style scoped lang="scss">
.shadow {
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.3);
}
.popup-box {
    width: 100%;
    position: fixed;
    background: #FFF;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 625px;
    margin: 0 auto;
    z-index: 999;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    .line {
        height: 5px;
        width: 35px;
        background: #cdcdcd;
        border-radius: 5px;
        margin: 15px auto 15px;
    }
    .reply {
        width: 100%;
        height: 50px;
        padding: 0 20px;
        line-height: 50px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        text-align: center;
        font-size: 20px;
        border-top: 1px solid #f3f3f3;
        span {
            color: #606060;
            font-size: 17px;
        }
    }
    .delete {
        width: 100%;
        height: 50px;
        padding: 0 20px;
        line-height: 50px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        text-align: center;
        font-size: 20px;
        border-top: 1px solid #f3f3f3;
        border-bottom: 1px solid #f3f3f3;
        span {
            color: #d81e06;
            font-size: 17px;
        }
    }
    .cancel-button {
        color: #606060;
        width: calc(100% - 40px);
        height: 45px;
        line-height: 45px;
        text-align: center;
        margin: 15px 20px 20px;
        border-radius: 8px;
        background: rgba(0,0,0,0.1);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
